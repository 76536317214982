// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import toastr from "toastr"

window.toastr = toastr
window.toastr.options = {
  "positionClass": "toast-bottom-right",
  "timeOut": 1000
};

Rails.start()
ActiveStorage.start()

console.log("application.js loaded");

$(() => {require("milestones")});
$(() => {if ($(".events").length) { require("events") }});
$(() => {if ($(".fights").length) { require("fights") }});
