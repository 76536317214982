class MilestoneDetailModal {

  init() {
    this.milestoneDetailModal = $('#milestone_detail_modal');
    this.milestoneDetailModalClose = $('#milestone_detail_modal_close');
    this.milestoneDetailModalFighterName = $('#milestone_detail_modal_fighter_name');
    this.milestoneDetailModalMilestoneName = $('#milestone_detail_modal_milestone_name');
    this.milestoneDetailModalBody = $('#milestone_detail_modal_body');

    $('body').on('click', '.milestoneDetailButton', milestone_detail_modal.milestoneDetailButtonOnClick);
    this.milestoneDetailModalClose.on('click', milestone_detail_modal.milestoneDetailCloseOnClick);
  };

  milestoneDetailButtonOnClick() {
    milestone_detail_modal.populateMilestoneDetailModal($(this).data('fighter-milestone-id'));
    milestone_detail_modal.milestoneDetailModal.removeClass('modal__hidden');
    milestone_detail_modal.milestoneDetailModal.addClass('modal__visible');
  };

  milestoneDetailCloseOnClick() {
    milestone_detail_modal.milestoneDetailModal.removeClass('modal__visible');
    milestone_detail_modal.milestoneDetailModal.addClass('modal__hidden');
  };

  populateMilestoneDetailModal(fighter_milestone_id) {
    let modal_body = '';
    let mdata = $('#fighter_milestone_' + fighter_milestone_id).data('milestone-detail');

    // Modal Header
    milestone_detail_modal.milestoneDetailModalFighterName.html(mdata.Fighter.FirstName + ' ' + mdata.Fighter.LastName);
    milestone_detail_modal.milestoneDetailModalMilestoneName.html($('#fighter_milestone_' + fighter_milestone_id).find('.milestoneName').html());

    // Image, first name, last name
    modal_body += '<div class="sideBySideContainers">';
    modal_body += '<div class="sideBySideContainers--container" style="text-align:center;"><img src="' + mdata.Fighter.LeftStanceLink + '" height="500"></div>';
    modal_body += '<div class="sideBySideContainers--container">';
    modal_body += '<h3 style="margin-top:0;">First Name</h3><span>' + mdata.Fighter.FirstName + '</span>';
    modal_body += '<h3>Last Name</h3><span>' + mdata.Fighter.LastName + '</span>';

    if (mdata.Event.IncludeDescription) {
      modal_body += '<h3>Heading Text</h3><span>' + mdata.Milestone.DisplayName + '</span>';
      modal_body += '<h3>Description</h3><span>' + mdata.Milestone.DisplayDescription + '</span>';
      modal_body += '<h3>Event Name</h3><span>' + mdata.Event.Description + '</span>';
      modal_body += '<h3>Opponent Info</h3><span>' + mdata.Fight.Description + '</span>';      
    } else {
      modal_body += '<h3>Description</h3><span>' + mdata.Milestone.DisplayDescription + '</span>';
    }
    modal_body += '</div></div>';

    milestone_detail_modal.milestoneDetailModalBody.html(modal_body);
  };

}

export let milestone_detail_modal = new MilestoneDetailModal();