class FightMilestonesLoader {

  init() {
    this.fightMilestonesButtonClass = '.fightMilestonesButton';
    this.fightMilestonesButton = $(this.fightMilestonesButtonClass);
    this.fightMilestonesRowClass = '.fightMilestonesRow';
    this.fightRowClass = '.fightRow';

    this.fightMilestonesButton.on('click', fight_milestones_loader.loadFightMilestones);
  };

  loadFightMilestones() {
    var $this = $(this);
    var fight_id = $(this).data('fight-id');
    
    toastr.info('Loading Milestones...');
    $('#fight_milestones_holder_' + fight_id).load('/fights/' + fight_id + '/ajax_milestones', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable to load milestones.');
      } else {
        toastr.success('Loaded.');
        $(fight_milestones_loader.fightMilestonesButtonClass).text('Milestones');
        $($this).text('Refresh');
        $(fight_milestones_loader.fightRowClass).removeClass('rowSelected');
        $('#fight_row_' + fight_id).addClass('rowSelected');
        $(fight_milestones_loader.fightMilestonesRowClass).addClass('visuallyHidden');
        $('#fight_milestones_holder_' + fight_id).removeClass('visuallyHidden');
        $('#fight_milestones_status_' + fight_id).html($('#fight_milestones_inner_holder_' + fight_id).data('fight-status-html'));
      }
    });
  };

}

export let fight_milestones_loader = new FightMilestonesLoader();